import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '/', redirect: { name: 'dashboard' } },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/view/pages/dashboard/dashboard.vue'),
      meta: {
        action: 'index',
        resource: 'freeRoute',
      },
    },
    {
      path: '/admin-data',
      name: 'admin-data',
      component: () => import('@/view/pages/adminData/adminData.vue'),
      meta: {
        action: 'admindata',
        resource: 'administration',
      },
    },
    {
      path: '/admin',
      name: 'admin',
      component: () => import('@/view/pages/admin/Admin.vue'),
      meta: {
        action: 'index',
        resource: 'freeRoute',
      },
      children: [
        {
          path: 'users',
          name: 'admin-users',
          component: () => import('@/view/pages/admin/users/TableUser.vue'),
          meta: {
            resource: 'users',
            action: 'index',
          },
        },
        {
          path: '/admin/menberships-dashboard',
          name: 'menberships-dashboard',
          component: () => import('@/view/pages/membership/adminMembership.vue'),
          meta: {
            action: 'index',
            resource: 'administrationMenberships',
          },
        },
        {
          path: '/admin/users/view/:id',
          name: 'admin-users-view',
          component: () => import('@/view/pages/admin/users/users-view/UsersView.vue'),
          meta: {
            action: 'show',
            resource: 'users',
          },
        },
        {
          path: '/admin/users/edit/:id',
          name: 'admin-users-edit',
          component: () => import('@/view/pages/admin/users/users-edit/UsersEdit.vue'),
          meta: {
            action: 'update',
            resource: 'users',
          },
        },
        {
          path: 'facilities',
          name: 'admin-facilities',
          component: () => import('@/view/pages/admin/facilities/TableFacility.vue'),
          meta: {
            action: 'index',
            resource: 'facilities',
          },
        },
        {
          path: 'facilities/view/:id',
          name: 'admin-facilities-view',
          component: () => import('@/view/pages/admin/facilities/FacilityView.vue'),
          meta: {
            action: 'show',
            resource: 'facilities',
          },
        },
        {
          path: 'facilities/edit/:id',
          name: 'admin-facilities-edit',
          component: () => import('@/view/pages/admin/facilities/FacilityEdit.vue'),
          meta: {
            action: 'update',
            resource: 'facilities',
          },
        },
        {
          path: 'appointment',
          name: 'admin-appointment',
          component: () => import('@/view/pages/admin/appointment/AppointmentRoute.vue'),
          children: [
            {
              path: 'campaign',
              name: 'admin-appointment-campaign',
              component: () =>
                import('@/view/pages/admin/appointment/campaign/campaign.vue'),
              meta: {
                action: 'index',
                resource: 'campaign',
              },
            },
            {
              path: 'visit',
              name: 'admin-appointment-visit',
              component: () =>
                import('@/view/pages/admin/appointment/visit/TableVisit.vue'),
              meta: {
                action: 'index',
                resource: 'type_visits',
              },
            },
            {
              path: 'membership-parameters',
              name: 'admin-appointment-membership-parameters',
              component: () =>
                import(
                  '@/view/pages/admin/appointment/membership-parameters/membershipSystem.vue'
                ),
              meta: {
                action: 'index',
                resource: 'membership-parameters',
              },
            },
            {
              path: 'appointment-messages',
              name: 'admin-appointment-messages',
              component: () =>
                import(
                  '@/view/pages/admin/appointment/appointment-messages/MessagesSystem.vue'
                ),
              meta: {
                action: 'index',
                resource: 'appointment-messages',
              },
            },
            {
              path: 'membership-parameters-lots',
              name: 'admin-appointment-membership-parameters-lots',
              component: () =>
                import(
                  '@/view/pages/admin/appointment/membership-parameters-lots/membershipSystemLots.vue'
                ),
              prosps: true,
              meta: {
                action: 'index',
                resource: 'membership-parameters-lots',
              },
            },
            {
              path: 'consult',
              name: 'admin-appointment-consult',
              component: () =>
                import('@/view/pages/admin/appointment/consult/TableConsult.vue'),
              meta: {
                action: 'index',
                resource: 'cities',
              },
            },
            {
              path: 'visit-status',
              name: 'admin-appointment-status',
              component: () =>
                import(
                  '@/view/pages/admin/appointment/visit-status/TableVisitStatus.vue'
                ),
              meta: {
                action: 'index',
                resource: 'visit_status',
              },
            },
            {
              path: 'packages',
              name: 'admin-appointment-packages',
              component: () =>
                import('@/view/pages/admin/appointment/packages/TablePackages.vue'),
              meta: {
                action: 'index',
                resource: 'medication',
              },
            },
            {
              path: 'medication',
              name: 'admin-appointment-medication',
              component: () =>
                import('@/view/pages/admin/appointment/medication/TableMedication.vue'),
              meta: {
                action: 'index',
                resource: 'medication',
              },
            },
            {
              path: 'allergies',
              name: 'admin-appointment-allergies',
              component: () =>
                import('@/view/pages/admin/appointment/allergies/TableAllergies.vue'),
              meta: {
                action: 'index',
                resource: 'allergies',
              },
            },
            {
              path: 'ailment',
              name: 'admin-appointment-ailment',
              component: () =>
                import('@/view/pages/admin/appointment/ailment/TableAilment.vue'),
              meta: {
                action: 'index',
                resource: 'ailments',
              },
            },
            {
              path: 'test',
              name: 'admin-appointment-test',
              component: () =>
                import('@/view/pages/admin/appointment/test/TableTest.vue'),
              meta: {
                action: 'index',
                resource: 'tests',
              },
            },
            {
              path: 'diagnostic-image',
              name: 'admin-appointment-diagnostic-image',
              component: () =>
                import(
                  '@/view/pages/admin/appointment/diagnostic-image/TableDiagnosticImage.vue'
                ),
              meta: {
                action: 'index',
                resource: 'diagnostic_imagings',
              },
            },
            {
              path: 'diagnostic-imaging-category',
              name: 'admin-diagnostic-imaging-category',
              component: () =>
                import(
                  '@/view/pages/admin/appointment/diagnostic-image-category/TableDiagnosticImageCategory.vue'
                ),
              meta: {
                action: 'index',
                resource: 'category_diagnostic_imagings',
              },
            },
            {
              path: 'procedures',
              name: 'admin-appointment-procedures',
              component: () =>
                import('@/view/pages/admin/appointment/procedures/TableProcedures.vue'),
              meta: {
                action: 'index',
                resource: 'procedures',
              },
            },
            {
              path: 'laboratory',
              name: 'admin-appointment-laboratory',
              component: () =>
                import('@/view/pages/admin/appointment/laboratory/TableLaboratory.vue'),
              meta: {
                action: 'index',
                resource: 'laboratory',
              },
            },
            {
              path: 'category_lab',
              name: 'admin-appointment-category_lab',
              component: () =>
                import(
                  '@/view/pages/admin/appointment/category_lab/TableCategoryLab.vue'
                ),
              meta: {
                action: 'index',
                resource: 'category_lab',
              },
            },
            {
              path: 'review-system',
              name: 'admin-review-system',
              component: () =>
                import(
                  '@/view/pages/admin/appointment/review-system/TableReviewSystem.vue'
                ),
              meta: {
                action: 'index',
                resource: 'review_of_system',
              },
            },
            {
              path: 'review-system-category',
              name: 'admin-category-review-system',
              component: () =>
                import(
                  '@/view/pages/admin/appointment/review-system-category/TableReviewSystemCategory.vue'
                ),
              meta: {
                action: 'index',
                resource: 'cat_review_of_system',
              },
            },
            {
              path: 'treatment',
              name: 'admin-appointment-treatment',
              component: () =>
                import('@/view/pages/admin/appointment/treatment/TableTreatment.vue'),
              meta: {
                action: 'index',
                resource: 'treatments',
              },
            },
            {
              path: 'diagnoses',
              name: 'admin-appointment-diagnoses',
              component: () =>
                import('@/view/pages/admin/appointment/diagnoses/TableDiagnoses.vue'),
              meta: {
                action: 'index',
                resource: 'diagnoses',
              },
            },
            {
              path: 'consult-price',
              name: 'admin-appointment-consult-price',
              component: () =>
                import(
                  '@/view/pages/admin/appointment/consult-price/TableConsultPrice.vue'
                ),
              meta: {
                action: 'index',
                resource: 'consult_prices',
              },
            },
            {
              path: 'promotion',
              name: 'admin-appointment-promotion',
              component: () =>
                import('@/view/pages/admin/appointment/promotion/TablePromotion.vue'),
              meta: {
                action: 'index',
                resource: 'promotions',
              },
            },
            {
              path: 'refund',
              name: 'admin-appointment-refund',
              component: () =>
                import('@/view/pages/admin/appointment/refund/TableRefund.vue'),
              meta: {
                action: 'index',
                resource: 'promotions',
              },
            },
          ],
        },
      ],
    },
    {
      path: '/dashboard-insurance',
      name: 'dashboard-insurance',
      component: () => import('@/view/pages/dashboard-insurance/dashboard.vue'),
      meta: {
        action: 'index',
        resource: 'freeRoute',
      },
    },
    {
      path: '/admin-insurance',
      name: 'administration-insurance',
      component: () => import('@/view/pages/adminInsurance/Admin.vue'),
      meta: {
        action: 'index',
        resource: 'freeRoute',
      },
      children: [
        {
          path: 'users-insurance',
          name: 'admin-users-insurance',
          component: () => import('@/view/pages/adminInsurance/users/TableUser.vue'),
          meta: {
            resource: 'users',
            action: 'index',
          },
        },
        {
          path: 'admin-data-insurance',
          name: 'admin-data-insurance',
          component: () => import('@/view/pages/adminInsurance/adminData/adminData.vue'),
          meta: {
            action: 'index',
            resource: 'data-insurance',
          },
        },
        {
          path: 'menberships-dashboard-insurance',
          name: 'menberships-dashboard-insurance',
          component: () =>
            import('@/view/pages/adminInsurance/membership/adminMembership.vue'),
          meta: {
            action: 'index',
            resource: 'administrationMenberships',
          },
        },
        {
          path: 'admin-insurance/users/view/:id',
          name: 'insurance-admin-users-view',
          component: () =>
            import('@/view/pages/adminInsurance/users/users-view/UsersView.vue'),
          meta: {
            action: 'show',
            resource: 'users',
          },
        },
        {
          path: 'admin-insurance/users/edit/:id',
          name: 'insurance-admin-users-edit',
          component: () =>
            import('@/view/pages/adminInsurance/users/users-edit/UsersEdit.vue'),
          meta: {
            action: 'update',
            resource: 'users',
          },
        },
        {
          path: 'facilities',
          name: 'admin-facilities',
          component: () =>
            import('@/view/pages/adminInsurance/facilities/TableFacility.vue'),
          meta: {
            action: 'index',
            resource: 'facilities',
          },
        },
        {
          path: 'facilities/view/:id',
          name: 'admin-facilities-view',
          component: () =>
            import('@/view/pages/adminInsurance/facilities/FacilityView.vue'),
          meta: {
            action: 'show',
            resource: 'facilities',
          },
        },
        {
          path: 'facilities/edit/:id',
          name: 'admin-facilities-edit',
          component: () =>
            import('@/view/pages/adminInsurance/facilities/FacilityEdit.vue'),
          meta: {
            action: 'update',
            resource: 'facilities',
          },
        },
        {
          path: 'appointment',
          name: 'admin-appointment',
          component: () =>
            import('@/view/pages/adminInsurance/appointment/AppointmentRoute.vue'),
          children: [
            {
              path: 'campaign',
              name: 'admin-appointment-campaign',
              component: () =>
                import('@/view/pages/adminInsurance/appointment/campaign/campaign.vue'),
              meta: {
                action: 'index',
                resource: 'campaign',
              },
            },
            {
              path: 'visit',
              name: 'admin-appointment-visit',
              component: () =>
                import('@/view/pages/adminInsurance/appointment/visit/TableVisit.vue'),
              meta: {
                action: 'index',
                resource: 'type_visits',
              },
            },
            {
              path: 'add-name-insurance',
              name: 'admin-appointment-visit',
              component: () =>
                import(
                  '@/view/pages/adminInsurance/appointment/add-name-insurance/TableName.vue'
                ),
              meta: {
                action: 'index',
                resource: 'add-name-insurance',
              },
            },
            {
              path: 'membership-parameters',
              name: 'admin-appointment-membership-parameters',
              component: () =>
                import(
                  '@/view/pages/adminInsurance/appointment/membership-parameters/membershipSystem.vue'
                ),
              meta: {
                action: 'index',
                resource: 'membership-parameters',
              },
            },
            {
              path: 'appointment-messages',
              name: 'admin-appointment-messages',
              component: () =>
                import(
                  '@/view/pages/adminInsurance/appointment/appointment-messages/MessagesSystem.vue'
                ),
              meta: {
                action: 'index',
                resource: 'appointment-messages',
              },
            },
            {
              path: 'membership-parameters-lots',
              name: 'admin-appointment-membership-parameters-lots',
              component: () =>
                import(
                  '@/view/pages/adminInsurance/appointment/membership-parameters-lots/membershipSystemLots.vue'
                ),
              prosps: true,
              meta: {
                action: 'index',
                resource: 'membership-parameters-lots',
              },
            },
            {
              path: 'consult',
              name: 'admin-appointment-consult',
              component: () =>
                import(
                  '@/view/pages/adminInsurance/appointment/consult/TableConsult.vue'
                ),
              meta: {
                action: 'index',
                resource: 'cities',
              },
            },
            {
              path: 'visit-status',
              name: 'admin-appointment-status',
              component: () =>
                import(
                  '@/view/pages/adminInsurance/appointment/visit-status/TableVisitStatus.vue'
                ),
              meta: {
                action: 'index',
                resource: 'visit_status',
              },
            },
            {
              path: 'packages',
              name: 'admin-appointment-packages',
              component: () =>
                import(
                  '@/view/pages/adminInsurance/appointment/packages/TablePackages.vue'
                ),
              meta: {
                action: 'index',
                resource: 'medication',
              },
            },
            {
              path: 'medication',
              name: 'admin-appointment-medication',
              component: () =>
                import(
                  '@/view/pages/adminInsurance/appointment/medication/TableMedication.vue'
                ),
              meta: {
                action: 'index',
                resource: 'medication',
              },
            },
            {
              path: 'allergies',
              name: 'admin-appointment-allergies',
              component: () =>
                import(
                  '@/view/pages/adminInsurance/appointment/allergies/TableAllergies.vue'
                ),
              meta: {
                action: 'index',
                resource: 'allergies',
              },
            },
            {
              path: 'ailment',
              name: 'admin-appointment-ailment',
              component: () =>
                import(
                  '@/view/pages/adminInsurance/appointment/ailment/TableAilment.vue'
                ),
              meta: {
                action: 'index',
                resource: 'ailments',
              },
            },
            {
              path: 'test',
              name: 'admin-appointment-test',
              component: () =>
                import('@/view/pages/adminInsurance/appointment/test/TableTest.vue'),
              meta: {
                action: 'index',
                resource: 'tests',
              },
            },
            {
              path: 'diagnostic-image',
              name: 'admin-appointment-diagnostic-image',
              component: () =>
                import(
                  '@/view/pages/adminInsurance/appointment/diagnostic-image/TableDiagnosticImage.vue'
                ),
              meta: {
                action: 'index',
                resource: 'diagnostic_imagings',
              },
            },
            {
              path: 'diagnostic-imaging-category',
              name: 'admin-diagnostic-imaging-category',
              component: () =>
                import(
                  '@/view/pages/adminInsurance/appointment/diagnostic-image-category/TableDiagnosticImageCategory.vue'
                ),
              meta: {
                action: 'index',
                resource: 'category_diagnostic_imagings',
              },
            },
            {
              path: 'procedures',
              name: 'admin-appointment-procedures',
              component: () =>
                import(
                  '@/view/pages/adminInsurance/appointment/procedures/TableProcedures.vue'
                ),
              meta: {
                action: 'index',
                resource: 'procedures',
              },
            },
            {
              path: 'laboratory',
              name: 'admin-appointment-laboratory',
              component: () =>
                import(
                  '@/view/pages/adminInsurance/appointment/laboratory/TableLaboratory.vue'
                ),
              meta: {
                action: 'index',
                resource: 'laboratory',
              },
            },
            {
              path: 'category_lab',
              name: 'admin-appointment-category_lab',
              component: () =>
                import(
                  '@/view/pages/adminInsurance/appointment/category_lab/TableCategoryLab.vue'
                ),
              meta: {
                action: 'index',
                resource: 'category_lab',
              },
            },
            {
              path: 'review-system',
              name: 'admin-review-system',
              component: () =>
                import(
                  '@/view/pages/adminInsurance/appointment/review-system/TableReviewSystem.vue'
                ),
              meta: {
                action: 'index',
                resource: 'review_of_system',
              },
            },
            {
              path: 'review-system-category',
              name: 'admin-category-review-system',
              component: () =>
                import(
                  '@/view/pages/adminInsurance/appointment/review-system-category/TableReviewSystemCategory.vue'
                ),
              meta: {
                action: 'index',
                resource: 'cat_review_of_system',
              },
            },
            {
              path: 'treatment',
              name: 'admin-appointment-treatment',
              component: () =>
                import(
                  '@/view/pages/adminInsurance/appointment/treatment/TableTreatment.vue'
                ),
              meta: {
                action: 'index',
                resource: 'treatments',
              },
            },
            {
              path: 'diagnoses',
              name: 'admin-appointment-diagnoses',
              component: () =>
                import(
                  '@/view/pages/adminInsurance/appointment/diagnoses/TableDiagnoses.vue'
                ),
              meta: {
                action: 'index',
                resource: 'diagnoses',
              },
            },
            {
              path: 'consult-price',
              name: 'admin-appointment-consult-price',
              component: () =>
                import(
                  '@/view/pages/adminInsurance/appointment/consult-price/TableConsultPrice.vue'
                ),
              meta: {
                action: 'index',
                resource: 'consult_prices',
              },
            },
            {
              path: 'promotion',
              name: 'admin-appointment-promotion',
              component: () =>
                import(
                  '@/view/pages/adminInsurance/appointment/promotion/TablePromotion.vue'
                ),
              meta: {
                action: 'index',
                resource: 'promotions',
              },
            },
            {
              path: 'refund',
              name: 'admin-appointment-refund',
              component: () =>
                import('@/view/pages/adminInsurance/appointment/refund/TableRefund.vue'),
              meta: {
                action: 'index',
                resource: 'promotions',
              },
            },
          ],
        },
      ],
    },
    {
      path: '/appointment',
      name: 'appointment',
      component: () => import('@/view/pages/appointment/TableAppointment.vue'),
      meta: {
        action: 'index',
        resource: 'appointment',
      },
    },
    {
      path: '/insurance/appointment',
      name: 'appointment-insurance',
      component: () => import('@/view/pages/appointmentInsurance/TableAppointment.vue'),
      meta: {
        action: 'index',
        resource: 'appointment-insurance',
      },
    },
    {
      path: '/recipe-patient/:id',
      name: 'recipe-patient',
      component: () => import('@/view/pages/appointment/actions/RecipePatient.vue'),
      meta: {
        resource: 'freeRoute',
      },
    },
    {
      path: '/insurance-recipe-patient/:id',
      name: 'insurance-recipe-patient',
      component: () =>
        import('@/view/pages/appointmentInsurance/actions/RecipePatient.vue'),
      meta: {
        resource: 'freeRoute',
      },
    },
    {
      path: '/patients',
      name: 'patients',
      component: () => import('@/view/pages/patient/PatientRoute.vue'),
      meta: {
        action: 'index',
        resource: 'freeRoute',
      },
      children: [
        {
          path: 'table',
          name: 'patient-table',
          component: () => import('@/view/pages/patient/TablePatient.vue'),
          meta: {
            action: 'index',
            resource: 'NurseProcedure',
          },
        },
        {
          path: ':id',
          name: 'patients-view',
          component: () => import('@/view/pages/patient/patient-view/PatientView.vue'),
          meta: {
            action: 'index',
            resource: 'NurseProcedure',
          },
        },
      ],
    },
    {
      path: '/insurance-patients',
      name: 'insurance-patients',
      component: () => import('@/view/pages/patientInsurance/PatientRoute.vue'),
      meta: {
        action: 'index',
        resource: 'freeRoute',
      },
      children: [
        {
          path: 'table',
          name: 'patient-table',
          component: () => import('@/view/pages/patientInsurance/TablePatient.vue'),
          meta: {
            action: 'index',
            resource: 'NurseProcedure',
          },
        },
        {
          path: ':id',
          name: 'insurance-patients-view',
          component: () =>
            import('@/view/pages/patientInsurance/patient-view/PatientView.vue'),
          meta: {
            action: 'index',
            resource: 'NurseProcedure',
          },
        },
      ],
    },
    {
      path: '/office-visit',
      name: 'office-visit',
      component: () => import('@/view/pages/office-visit/OfficeVisit.vue'),
      meta: {
        action: 'index',
        resource: 'freeRoute',
      },
      children: [
        {
          path: 'table',
          name: 'office-visit-view-table',
          component: () => import('@/view/pages/office-visit/TableOfficeVisit.vue'),
          meta: {
            action: 'index',
            resource: 'NurseProcedure',
          },
        },
        {
          path: 'nurse/:id',
          name: 'office-visit-view-nurse',
          component: () =>
            import('@/view/pages/office-visit/procedures/NurseProcedure.vue'),
          meta: {
            action: 'index',
            resource: 'NurseProcedure',
          },
        },
        {
          path: 'oma/:id',
          name: 'office-visit-view-oma',
          component: () =>
            import('@/view/pages/office-visit/procedures/OmaProcedure.vue'),
          meta: {
            action: 'index',
            resource: 'OmaProcedure',
          },
        },
        {
          path: 'recipe/:id',
          name: 'office-visit-recipe',
          component: () => import('@/view/pages/office-visit/recipe/RecipeInfo.vue'),
          meta: {
            action: 'index',
            resource: 'Recipe',
          },
        },
      ],
    },
    {
      path: '/insurance-office-visit',
      name: 'insurance-office-visit',
      component: () => import('@/view/pages/office-visitInsurance/OfficeVisit.vue'),
      meta: {
        action: 'index',
        resource: 'freeRoute',
      },
      children: [
        {
          path: 'table',
          name: 'office-visit-view-table',
          component: () =>
            import('@/view/pages/office-visitInsurance/TableOfficeVisit.vue'),
          meta: {
            action: 'index',
            resource: 'insurance-NurseProcedure',
          },
        },
        {
          path: 'nurse/:id',
          name: 'office-visit-view-nurse-insurance',
          component: () =>
            import('@/view/pages/office-visitInsurance/procedures/NurseProcedure.vue'),
          meta: {
            action: 'index',
            resource: 'insurance-NurseProcedure',
          },
        },
        {
          path: 'oma/:id',
          name: 'office-visit-view-insurance-oma',
          component: () =>
            import('@/view/pages/office-visitInsurance/procedures/OmaProcedure.vue'),
          meta: {
            action: 'index',
            resource: 'insurance-OmaProcedure',
          },
        },
        {
          path: 'recipe/:id',
          name: 'office-visit-recipe-Insurance',
          component: () =>
            import('@/view/pages/office-visitInsurance/recipe/RecipeInfo.vue'),
          meta: {
            action: 'index',
            resource: 'Recipe',
          },
        },
      ],
    },
    {
      path: '/cash-account',
      name: 'cash-account',
      component: () => import('@/view/pages/cash-account/CashAccount.vue'),
      meta: {
        action: 'index',
        resource: 'cash_accounts',
      },
    },
    {
      path: '/insurance-cash-account',
      name: 'insurance-cash-account',
      component: () => import('@/view/pages/cash-account/CashAccount.vue'),
      meta: {
        action: 'index',
        resource: 'cash_accounts',
      },
    },
    {
      path: '/not-closed-cash',
      name: 'not-closed-cash',
      component: () =>
        import('@/view/pages/cash-account/not-closed-cash/TableNClosedCash.vue'),
      meta: {
        action: 'index',
        resource: 'not_closed_cash',
      },
    },
    {
      path: '/insurance-not-closed-cash',
      name: 'insurance-not-closed-cash',
      component: () =>
        import('@/view/pages/cash-account/not-closed-cash/TableNClosedCash.vue'),
      meta: {
        action: 'index',
        resource: 'not_closed_cash',
      },
    },
    {
      path: '/cash-closing',
      name: 'cash-closing',
      component: () =>
        import('@/view/pages/cash-account/cash-closing/FormCashClosing.vue'),
      meta: {
        action: 'index',
        resource: 'cash_closing',
      },
    },
    {
      path: '/insurance-cash-closing',
      name: 'insurance-cash-closing',
      component: () =>
        import('@/view/pages/cash-account/cash-closing/FormCashClosing.vue'),
      meta: {
        action: 'index',
        resource: 'cash_closing',
      },
    },
    {
      path: '/cash-closing/:id',
      name: 'cash-closing-by-id',
      component: () =>
        import('@/view/pages/cash-account/cash-closing/FormCashClosing.vue'),
      meta: {
        action: 'index',
        resource: 'cash_closing',
      },
    },
    {
      path: '/insurance-cash-closing/:id',
      name: 'insurance-cash-closing-by-id',
      component: () =>
        import('@/view/pages/cash-account/cash-closing/FormCashClosing.vue'),
      meta: {
        action: 'index',
        resource: 'cash_closing',
      },
    },
    {
      path: '/print-cash',
      name: 'print-cash',
      component: () => import('@/view/pages/cash-account/cash-closing/printCash.vue'),
      meta: {
        action: 'index',
        resource: 'cash_closing',
      },
    },
    {
      path: '/insurance-print-cash',
      name: 'insurance-print-cash',
      component: () => import('@/view/pages/cash-account/cash-closing/printCash.vue'),
      meta: {
        action: 'index',
        resource: 'cash_closing',
      },
    },
    {
      path: '/cash-daily-boxes',
      name: 'cash-daily-boxes',
      component: () => import('@/view/pages/cash-account/cash-daily/TableCashDaily.vue'),
    },
    {
      path: '/insurance-cash-daily-boxes',
      name: 'insurance-cash-daily-boxes',
      component: () => import('@/view/pages/cash-account/cash-daily/TableCashDaily.vue'),
    },
    {
      path: '/cash-history/:id',
      name: 'cash-history',
      component: () => import('@/view/pages/cash-account/cash-history/TableCash.vue'),
    },
    {
      path: '/insurance-cash-history/:id',
      name: 'insurance-cash-history',
      component: () => import('@/view/pages/cash-account/cash-history/TableCash.vue'),
    },
    {
      path: '/builder',
      name: 'builder',
      component: () => import('@/view/pages/Builder.vue'),
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/view/pages/profile/Profile.vue'),
      meta: {
        action: 'index',
        resource: 'freeRoute',
      },
      children: [
        {
          path: 'profile-1',
          name: 'profile-1',
          component: () => import('@/view/pages/profile/Profile-1.vue'),
        },
        {
          path: 'profile-2',
          name: 'profile-2',
          component: () => import('@/view/pages/profile/Profile-2.vue'),
        },
        {
          path: 'profile-3',
          name: 'profile-3',
          component: () => import('@/view/pages/profile/Profile-3.vue'),
        },
        {
          path: 'profile-4',
          name: 'profile-4',
          component: () => import('@/view/pages/profile/Profile-4.vue'),
        },
      ],
    },
    {
      path: '/custom-error',
      name: 'error',
      component: () => import('@/view/pages/error/Error.vue'),
      meta: {
        action: 'index',
        resource: 'freeRoute',
      },
      children: [
        {
          path: 'error-1',
          name: 'error-1',
          component: () => import('@/view/pages/error/Error-1.vue'),
          meta: {
            layout: 'full',
          },
        },
        {
          path: 'error-2',
          name: 'error-2',
          component: () => import('@/view/pages/error/Error-2.vue'),
        },
        {
          path: 'error-3',
          name: 'error-3',
          component: () => import('@/view/pages/error/Error-3.vue'),
        },
        {
          path: 'error-4',
          name: 'error-4',
          component: () => import('@/view/pages/error/Error-4.vue'),
        },
        {
          path: 'error-5',
          name: 'error-5',
          component: () => import('@/view/pages/error/Error-5.vue'),
        },
        {
          path: 'error-6',
          name: 'error-6',
          component: () => import('@/view/pages/error/Error-6.vue'),
        },
      ],
    },
    {
      name: 'login',
      path: '/login',
      component: () => import('@/view/pages/auth/login_pages/Login-1'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/get-cupon/:cupon',
      name: 'get-cupon',
      component: () => import('@/view/pages/landings/cupons/getCupons.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: '/404',
    },
    {
      // the 404 route, when none of the above matches
      path: '/404',
      name: '404',
      component: () => import('@/view/pages/error/Error-1.vue'),
    },
  ],
})
